
import { defineComponent } from "vue";


export default defineComponent({
  name: "kt-activity-drawer",
  components: {},
  setup() {
    return {
    }
  }
});

